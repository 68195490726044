<template>
  <div class="page-container">
    <!--头部图-->
    <div class="header-box">
      <!--图片-->
      <div class="image imagesBg doc flex flex-center flex-dr">
        <h2>{{aboutManage.texts[0]}}</h2>
        <h3>{{aboutManage.texts[1]}}</h3>
      </div>
    </div>
    <!--是谁-->
    <div class="who-box flex flex-between">
      <div class="title">{{aboutManage.texts[2]}}</div>
      <div class="des">
        <p>{{aboutManage.texts[3]}}</p>
        <p>{{aboutManage.texts[4]}}</p>
        <p>{{aboutManage.texts[5]}}</p>
      </div>
    </div>
    <!--中间图-->
    <div class="middle-img-1 imagesBg"></div>
    <!--研究和创新-->
    <div class="research-box">
      <h2 class="title">{{aboutManage.texts[6]}}</h2>
      <h3 class="des des-title">
        {{aboutManage.texts[7]}}
      </h3>
      <div class="four-box">
        <div class="li flex flex-between">
          <div class="square">
            <div class="title">{{aboutManage.texts[8]}}</div>
            <div class="des">{{aboutManage.texts[9]}}</div>
          </div>
          <div class="square">
            <div class="title">{{aboutManage.texts[10]}}</div>
            <div class="des">{{aboutManage.texts[11]}}
            </div>
          </div>
        </div>
        <div class="li flex flex-between">
          <div class="square">
            <div class="title">{{aboutManage.texts[12]}}</div>
            <div class="des">{{aboutManage.texts[13]}}
            </div>
          </div>
          <div class="square">
            <div class="title">{{aboutManage.texts[14]}}</div>
            <div class="des">{{aboutManage.texts[15]}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--带来什么-->
    <div class="get-box imagesBg doc-box  flex flex-dr flex-center">

        <h2>{{aboutManage.texts[16]}}</h2>
        <div class="li-box flex flex-between">
          <div class="li flex flex-dr flex-center">
            <img src="http://resouce.cdzyhd.com/543ced94-cb1a-4dc5-941c-a394792a326a.svg" alt="">
            <div class="title">{{aboutManage.texts[17]}}</div>
            <div class="des">{{aboutManage.texts[18]}}</div>
          </div>
          <div class="li flex flex-dr flex-center">
            <img src="http://resouce.cdzyhd.com/10f39c51-cce4-4155-97f2-98c4492d9ff0.svg" alt="">
            <div class="title">{{aboutManage.texts[19]}}</div>
            <div class="des">{{aboutManage.texts[20]}}</div>
          </div>
          <div class="li flex flex-dr flex-center">
            <img src="http://resouce.cdzyhd.com/6e23ebc7-605a-4494-a6a1-96b24dc9070e.svg" alt="">
            <div class="title">{{aboutManage.texts[21]}}</div>
            <div class="des">{{aboutManage.texts[22]}}</div>
          </div>
          <div class="li flex flex-dr flex-center">
            <img src="http://resouce.cdzyhd.com/03cdd292-df0e-488e-9380-f3214fb3a043.svg" alt="">
            <div class="title">{{aboutManage.texts[23]}}</div>
            <div class="des">{{aboutManage.texts[24]}}</div>
          </div>
        </div>

    </div>
    <!--开放合作共赢-->
    <div class="open-box flex flex-dr flex-center">
      <h2>{{aboutManage.texts[25]}}</h2>
      <h3>{{aboutManage.texts[26]}}</h3>
      <div class="li-box flex flex-around">
        <div class="li flex flex-center flex-dr">
          <img src="http://resouce.cdzyhd.com/c4d62c60-5f44-4803-8a8d-17553b416ed1.svg" alt="">
          <div class="title">{{aboutManage.texts[27]}}</div>
          <div class="des">{{aboutManage.texts[28]}}</div>
        </div>
        <div class="li flex flex-center flex-dr">
          <img src="http://resouce.cdzyhd.com/5dd5614c-b9c8-42cd-b8bb-922111592e76.svg" alt="">
          <div class="title">{{aboutManage.texts[29]}}</div>
          <div class="des">{{aboutManage.texts[30]}}</div>
        </div>
        <div class="li flex flex-center flex-dr">
          <img src="http://resouce.cdzyhd.com/bdc64f33-7163-4ee3-9f9e-d7c21f42b7df.svg" alt="">
          <div class="title">{{aboutManage.texts[31]}}</div>
          <div class="des">{{aboutManage.texts[32]}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ConfigModel} from "../model/erp/ConfigModel";
import $ from "jquery";

export default {
  name: "about",
  data() {
    return {
      aboutManage: {
        texts: [],
        images: []
      }
    }
  },
  async mounted() {
    $("#header-container .navs .about").addClass("active") // 顶部导航增加active
    // 获取关于我们配置
    let aboutManage = await ConfigModel.getAboutConfig()
    this.$set(this, "aboutManage", aboutManage);
    this.$nextTick(()=>{
      // 渲染背景图
      let allImageElement= $(".imagesBg");
      for (let i = 0; i < allImageElement.length; i++) {
        let ele=allImageElement[i]
        $(ele).css("backgroundImage",`url("${this.aboutManage.images[i]}")`)
      }
    })
  },
  methods: {}
}
</script>
<style>
#header-container {
  background-color: rgba(0, 0, 0, 0.4);
}

#header-container .navs a {
  color: #fff !important;
  opacity: 0.6;
}

#header-container .navs a:hover {
  opacity: 1;
}

#header-container img.black {
  display: none;
}

#header-container .product-navs .list {
  display: none;
}
</style>
<style scoped lang="less">
.header-box {
  position: relative;
  width: 100%;
  height: 620px;
  margin-bottom: 70px;
  // 图片
  .image {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 0;
    width: 100%;
    height: 620px;
    background-size: cover;
    background-position: 50% 50%;
    -webkit-backface-visibility: hidden;
    //background-image: url("http://resouce.cdzyhd.com/8ca605eb-5c8b-47c5-aa86-5733a03a5339.png");
  }

  // 文字
  .doc {
    color: #fff;

    h2 {
      font-size: 36px;
      font-weight: 500;
      line-height: 61px;
      color: #FFFFFF;
    }

    h3 {
      font-size: 16px;
      font-weight: 500;
      line-height: 27px;
      color: #FFFFFF;
    }
  }
}

.who-box {
  width: 1280px;
  margin: 0 auto;

  .title {
    width: 400px;
    height: 245px;
    font-size: 36px;
    font-weight: 500;
    line-height: 200px;
    color: #333333;
  }

  .des {
    width: 880px;
    height: 245px;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    color: #333333;
    opacity: 1;

    p {
      margin: 0;
      padding: 0;
      text-indent: 2em;
    }
  }

}

.middle-img-1 {
  width: 1280px;
  height: 600px;
  margin: 0 auto;
  background-size: cover;
  background-position: 50% 50%;
  -webkit-backface-visibility: hidden;
  margin-bottom: 50px;
  //background-image: url("http://resouce.cdzyhd.com/8b833646-c673-4c7a-892c-d46823e9e686.png");
}

.research-box {
  width: 1280px;
  margin: 0 auto;

  h2.title {
    font-size: 36px;
    font-weight: 500;
    line-height: 61px;
    color: #333333;
    text-align: center;
  }

  .des {
    width: 1275px;
    height: 97px;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    color: #333333;
    text-indent: 2em;
  }

  .des-title{
    height: 120px;
  }

  .four-box {
    width: 700px;
    margin: 0 auto;
    margin-bottom: 20px;

    .li {
      width: 100%;

      .square {
        width: 250px;

        .title {
          width: 250px;
          font-size: 36px;
          font-weight: 500;
          line-height: 61px;
          color: #333333;
          margin-bottom: 20px;
          text-align: center;
        }

        .des {
          width: 250px;
          font-size: 16px;
          font-weight: 400;
          line-height: 27px;
          color: #888888;
          opacity: 1;
        }
      }
    }
  }
}

.get-box {
  position: relative;
  width: 100%;
  height: 600px;
  background-size: cover;
  background-position: 50% 50%;
  -webkit-backface-visibility: hidden;
  //background-image: url("http://resouce.cdzyhd.com/636b7ca8-1f7c-484f-9f43-1f8bd523e4d0.png");



    h2 {
      font-size: 36px;
      font-weight: 500;
      line-height: 61px;
      color: #FFFFFF;
      // todo 距离
      padding-bottom: 50px;
    }

    .li-box {
      width: 1000px;
      padding-bottom: 100px;
    }

    .li {
      img {
        width: 62px;
        height: 47px;
        margin-bottom: 20px;
      }

      .title {
        font-size: 26px;
        font-weight: 500;
        line-height: 44px;
        color: #FFFFFF;
        margin-bottom: 20px;
      }

      .des {
        font-size: 16px;
        font-weight: 500;
        line-height: 27px;
        color: #FFFFFF;
        max-width: 200px;
        text-align: center;
      }
    }

}

.open-box {
  width: 1280px;
  margin: 0 auto;
  margin-bottom: 70px;

  h2 {
    font-size: 36px;
    font-weight: 500;
    line-height: 61px;
    color: #333333;
  }

  h3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    color: #333333;
    margin-bottom: 50px;
    width: 670px;
    text-align: center;
  }

  .li-box {
    width: 1100px;
  }

  .li {
    min-width: 230px;
    img {
      width: 56px;
      height: 56px;
      margin-bottom: 10px;
    }

    .title {
      font-size: 26px;
      font-weight: 500;
      line-height: 44px;
      color: #333333;
      margin-bottom: 20px;
    }

    .des {
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
      color: #333333;
      max-width: 230px;
      text-align: center;
    }
  }
}
</style>
